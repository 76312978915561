:root {
  /* Colors */
  --theme-white: #FFFFFF;
  --theme-dark-yellow: #22bed9;
  --theme-light-yellow: #FFE54C;
  --theme-background: #1a1525;
  --theme-container: #272231;
  --theme-light-blue-90: #22bed9; /* 90% transparency */
  --theme-green-90: #36CAB0E6; /* 90% transparency */
  --theme-red-90: #f15e7e; /* 90% transparency */

  /* Wallet controls */
  --theme-connected-chain-id-background-color: var(--theme-dark-yellow);

  /* Generic styles */
  --theme-token-sale-heading-color: var(--theme-white);
  --theme-background-color: var(--theme-background);
  --theme-card-background-color: var(--theme-container);
  --theme-card-border-color: var(--theme-card-background-color);
  --theme-secondary-text-opacity: 0.6;
  --theme-primary-button-background-color: var(--theme-dark-yellow);
  --theme-primary-button-text-color: var(--theme-background);

  /* Swap card form */
  --theme-from-amount-max-button-color: var(--theme-dark-yellow);

  /*
     Swap card overlay - displayed after clicking swap button, has various states that tracks
     transaction from extension prompt through success/failure
   */
  --theme-swap-card-overlay-waitingForExtension-background-color: var(--theme-light-blue-90);
  --theme-swap-card-overlay-pending-background-color: var(--theme-light-blue-90);
  --theme-swap-card-overlay-complete-background-color: var(--theme-green-90);
  --theme-swap-card-overlay-error-background-color: var(--theme-red-90);
  --theme-swap-card-overlay-complete-continue-button-background-color: var(--theme-container);
  --theme-swap-card-overlay-error-continue-button-background-color: #A43D4A;

  /* Historical price card/chart */
  --theme-historical-price-card-option-button-background-color: var(--theme-dark-yellow);
  --theme-historical-price-card-option-button-text-color: var(--theme-container);
  --theme-historical-price-chart-line-color: var(--theme-dark-yellow);
  --theme-historical-price-chart-area-gradient-from-color: var(--theme-dark-yellow);
  --theme-historical-price-chart-area-gradient-to-color: var(--theme-light-yellow);
}
