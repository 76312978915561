@import 'theme.css';
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-white font-medium;
  background-color: var(--theme-background-color);
  font-family: 'Inter', sans-serif;
}

.btn-primary {
  @apply text-sm font-bold;
  background-color: var(--theme-primary-button-background-color);
  color: var(--theme-primary-button-text-color);
}

.btn-disabled {
  @apply bg-gray-400 cursor-not-allowed text-black;
}

.text-secondary {
  @apply text-xs;
  opacity: var(--theme-secondary-text-opacity);
}
