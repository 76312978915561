.swap-card-overlay {
  @apply px-12 pt-16 pb-8 flex justify-between items-center flex-col backdrop-filter backdrop-blur-sm transition-colors duration-300 ease-out;
}

.swap-card-overlay h3 {
  @apply text-lg;
}

.swap-card-overlay--waitingForExtension {
  background-color: var(--theme-swap-card-overlay-waitingForExtension-background-color);
}

.swap-card-overlay--pending {
  background-color: var(--theme-swap-card-overlay-pending-background-color);
}

.swap-card-overlay--complete {
  background-color: var(--theme-swap-card-overlay-complete-background-color);
}

.swap-card-overlay--error {
  background-color: var(--theme-swap-card-overlay-error-background-color);
}

.swap-card-overlay button {
  @apply text-sm rounded-lg w-full font-bold py-2;
}

.swap-card-overlay--complete button {
  background-color: var(--theme-swap-card-overlay-complete-continue-button-background-color);
}

.swap-card-overlay--error button {
  background-color: var(--theme-swap-card-overlay-error-continue-button-background-color);
}
